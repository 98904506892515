var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Collapse",
    {
      staticClass: "personInfoDiv",
      on: { "on-change": _vm.CollapseChange },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "Panel",
        {
          attrs: { name: "1" },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function () {
                return [
                  _c(
                    "Card",
                    [
                      _c(
                        "Form",
                        [
                          _c(
                            "Row",
                            {
                              staticStyle: { "flex-wrap": "nowrap" },
                              attrs: { gutter: 10 },
                            },
                            [
                              _c(
                                "Col",
                                {
                                  staticStyle: { "min-width": "120px" },
                                  attrs: { span: "3" },
                                },
                                [
                                  _c("div", { staticClass: "pic" }, [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "viewer",
                                            rawName: "v-viewer",
                                            value: { movable: false },
                                            expression: "{movable: false}",
                                          },
                                        ],
                                        staticClass: "pic-box",
                                        style: {
                                          border: _vm.personInfo.avatar
                                            ? "0 !important;"
                                            : "auto",
                                        },
                                      },
                                      [
                                        _vm.personInfo.avatar
                                          ? _c("img", {
                                              staticStyle: {
                                                height: "100%",
                                                width: "100%",
                                              },
                                              attrs: {
                                                src: _vm.srcImage(
                                                  _vm.personInfo.avatar
                                                ),
                                              },
                                            })
                                          : _vm._e(),
                                        !_vm.personInfo.avatar
                                          ? _c("img", {
                                              staticStyle: {
                                                height: "100%",
                                                width: "100%",
                                                padding: "5px",
                                              },
                                              attrs: {
                                                src: require("../../../../assets/user-avatar.png"),
                                              },
                                            })
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "Col",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "column",
                                  },
                                  attrs: { span: "21" },
                                },
                                [
                                  _c(
                                    "Row",
                                    { staticClass: "txtFrom" },
                                    [
                                      _c(
                                        "Col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "FormItem",
                                            { attrs: { label: "体检编号" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.personInfo.test_num ||
                                                      _vm.personInfo.testNum
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "FormItem",
                                            { attrs: { label: "身份证号" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.personInfo.id_card ||
                                                      _vm.personInfo.idCard
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "FormItem",
                                            {
                                              attrs: {
                                                label: _vm.setLabel("姓名"),
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.personInfo
                                                      .person_name ||
                                                      _vm.personInfo.personName
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "FormItem",
                                            {
                                              attrs: {
                                                label: _vm.setLabel("性别"),
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.personInfo.sex) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "Row",
                                    { staticClass: "txtFrom" },
                                    [
                                      _c(
                                        "Col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "FormItem",
                                            {
                                              attrs: {
                                                label: _vm.setLabel("年龄"),
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.personInfo.age) +
                                                  "岁 "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "FormItem",
                                            { attrs: { label: "电话号码" } },
                                            [
                                              _c(
                                                "Tooltip",
                                                {
                                                  attrs: {
                                                    transfer: "",
                                                    "max-width": "200",
                                                    content:
                                                      _vm.personInfo.mobile,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.personInfo.mobile
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c(
                                            "FormItem",
                                            { attrs: { label: "单位名称" } },
                                            [
                                              _c(
                                                "Tooltip",
                                                {
                                                  attrs: {
                                                    transfer: "",
                                                    "max-width": "200",
                                                    content:
                                                      _vm.personInfo.unit_name,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.personInfo
                                                          .unit_name &&
                                                          _vm.personInfo
                                                            .unit_name.length >
                                                            12
                                                          ? _vm.personInfo.unit_name.substring(
                                                              0,
                                                              10
                                                            ) + "..."
                                                          : _vm.personInfo
                                                              .unit_name
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "Row",
                                    { staticClass: "txtFrom" },
                                    [
                                      _vm.personInfo.physical_type ==
                                        "职业体检" ||
                                      _vm.personInfo.physicalType ==
                                        "职业体检" ||
                                      _vm.personInfo.physical_type ==
                                        "放射体检" ||
                                      _vm.personInfo.physicalType == "放射体检"
                                        ? _c(
                                            "Col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: { label: "危害因素" },
                                                },
                                                [
                                                  _c(
                                                    "Tooltip",
                                                    {
                                                      attrs: {
                                                        transfer: "",
                                                        "max-width": "200",
                                                        content:
                                                          _vm.personInfo
                                                            .hazard_factors_text,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.personInfo
                                                              .hazard_factors_text ||
                                                              _vm.personInfo
                                                                .hazardFactorsText
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.personInfo.physical_type ==
                                        "职业体检" ||
                                      _vm.personInfo.physicalType ==
                                        "职业体检" ||
                                      _vm.personInfo.physical_type ==
                                        "放射体检" ||
                                      _vm.personInfo.physicalType == "放射体检"
                                        ? _c(
                                            "Col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: { label: "检查种类" },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.personInfo
                                                          .work_state_text ||
                                                          _vm.personInfo
                                                            .workStateText
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "Col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "FormItem",
                                            { attrs: { label: "登记时间" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.personInfo.regist_date
                                                      ? _vm.personInfo.regist_date.split(
                                                          "."
                                                        )[0]
                                                      : null ||
                                                          _vm.personInfo
                                                            .registDate
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.personInfo.physical_type == "职业体检" ||
                                  _vm.personInfo.physicalType == "职业体检" ||
                                  _vm.personInfo.physical_type == "放射体检" ||
                                  _vm.personInfo.physicalType == "放射体检"
                                    ? _c(
                                        "Row",
                                        { staticClass: "txtFrom" },
                                        [
                                          _c(
                                            "Col",
                                            { attrs: { span: 12 } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: {
                                                    label: "目标职业病",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "Tooltip",
                                                    {
                                                      attrs: {
                                                        transfer: "",
                                                        "max-width": "200",
                                                        content:
                                                          _vm.personInfo
                                                            .occupational_diseases,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.personInfo
                                                              .occupational_diseases &&
                                                              _vm.personInfo
                                                                .occupational_diseases
                                                                .length > 20
                                                              ? _vm.personInfo.occupational_diseases.substring(
                                                                  0,
                                                                  10
                                                                ) + "..."
                                                              : _vm.personInfo
                                                                  .occupational_diseases
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "Col",
                                            { attrs: { span: 12 } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: {
                                                    label: "目标职业禁忌证",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "Tooltip",
                                                    {
                                                      attrs: {
                                                        transfer: "",
                                                        "max-width": "200",
                                                        content:
                                                          _vm.personInfo
                                                            .occupational_taboo,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.personInfo
                                                              .occupational_taboo &&
                                                              _vm.personInfo
                                                                .occupational_taboo
                                                                .length > 20
                                                              ? _vm.personInfo.occupational_taboo.substring(
                                                                  0,
                                                                  10
                                                                ) + "..."
                                                              : _vm.personInfo
                                                                  .occupational_taboo
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" 体检人员信息 "),
          _vm.personId && _vm.personId.trim().length > 0 && !_vm.isOpen
            ? _c("span", { staticClass: "personInfoPanelSpan" }, [
                _c("span", { staticStyle: { "margin-left": "20px" } }, [
                  _vm._v(
                    " 姓名: " +
                      _vm._s(
                        _vm.personInfo.person_name || _vm.personInfo.personName
                      )
                  ),
                ]),
                _c("span", { staticStyle: { "margin-left": "20px" } }, [
                  _vm._v(" 性别: " + _vm._s(_vm.personInfo.sex)),
                ]),
                _c("span", { staticStyle: { "margin-left": "20px" } }, [
                  _vm._v("年龄: " + _vm._s(_vm.personInfo.age) + "岁"),
                ]),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }